import React, { Component } from 'react';
import { Emoji } from 'emoji-mart';
import './MessageList.css';
import 'emoji-mart/css/emoji-mart.css';
import Moment from 'react-moment';

class MessageList extends Component {
  constructor(props) {
    super(props);
  }

  //<p className="message-text" dangerouslySetInnerHTML={{__html: this.filterMessage(message.text,this.props.currentId)}}></p>
  //<span dangerouslySetInnerHTML={{__html: Emoji({html: true, set: 'apple', emoji: '+1', size: 24 })}}></span>
  //{message.text.replace(new RegExp('(^|\\s)(\:[a-zA-Z0-9-_+]+\:(\:skin-tone-[2-6]\:)?)', 'g'), "<Emoji set='messenger' emoji='$2' size='32' />")}

  render() {
    const userid = this.props.currentId;
    return (
      <ul id="message-list" className="message-list">
        {this.props.messages.map((message, index) => {
          let matchArr;
          let lastOffset = 0;
          const regex = new RegExp('(\:[a-zA-Z0-9-_+]+\:(\:skin-tone-[2-6]\:)?)', 'g');
          const partsOfTheMessageText = [];
          while ((matchArr = regex.exec(message.text)) !== null) {
            const previousText = message.text.substring(lastOffset, matchArr.index);
            if (previousText.length) partsOfTheMessageText.push(previousText);

            lastOffset = matchArr.index + matchArr[0].length;

           const emoji = (
              <Emoji
                emoji={matchArr[0]}
                set="facebook"
                size={22}
                fallback={(em, props) => {
                  return em ? `:${em.short_names[0]}:` : props.emoji;
                }}
              />
            );

            if (emoji) {
              partsOfTheMessageText.push(emoji);
            } else {
              partsOfTheMessageText.push(matchArr[0]);
            }
          }

          const finalPartOfTheText = message.text.substring(lastOffset, message.text.length);
          if (finalPartOfTheText.length) partsOfTheMessageText.push(finalPartOfTheText);
          let messageSplit = partsOfTheMessageText[0].toString().split(" ");
          messageSplit.map(function(words) {
            //let the_at = words.charAt(0);
            if (words === '@'+userid && words.search("@") !== '-1') {
              //partsOfTheMessageText.unshift(<div class="mention"></div>);
              partsOfTheMessageText.push(<span className="mention"></span>);
            }
          });
          return <li key={index}><h4 className="message-sender">{message.senderId} <span><Moment format="MMMM Do h:mm:ss a">{message.createdAt}</Moment></span></h4><p>{partsOfTheMessageText.map(p => <span>{p}</span>)}</p></li>
        })}
      </ul>
    )
  }
}

export default MessageList;
