import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
//import Sortable from 'sortablejs';
import firebase from 'firebase';
import Menu from './components/Menu/Menu';
import Player from './components/Player/Player';
import Playlists from './components/Playlists/Playlists';
import Home from './components/Home/Home';
import Login from './components/Login/Login';
import MusicCatalog from './components/MusicCatalog/MusicCatalog';
import Logout from './components/Logout/Logout';
import ChatMessage from './components/ChatMessage/ChatMessage';
import ChatApp from './components/ChatApp/ChatApp';
import Sync from './components/Sync/Sync';
import logoOTN from './images/offtoneverland.jpg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/styles.css';
import $ from 'jquery';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-82751-3');
ReactGA.pageview(window.location.pathname + window.location.search);
//firebase deploy --token 1//0fy5-NBJiLnuxCgYIARAAGA8SNwF-L9Irc7qK4mKfOqYbBLI4Oz6p6Ufjw2pvMyAj3nDEMGEAEd88Du3WYuKbaxdqSqOAV_LaVSE
var config = {
  apiKey: "AIzaSyBqrSOu--N7LsEUHE7eILBJgzDuZaNIvtE",
  authDomain: "dcot-jukebox.firebaseapp.com",
  databaseURL: "https://dcot-jukebox.firebaseio.com",
  projectId: "dcot-jukebox",
  storageBucket: "dcot-jukebox.appspot.com",
  messagingSenderId: "259378447020"
};
firebase.initializeApp(config);

class App extends Component {
  constructor(props) {
    super(props);
    let playlistName = localStorage.getItem('curPlaylist'),
        playlistID = localStorage.getItem('curPlaylistID'),
        search = window.location.search,
        params = new URLSearchParams(search),
        friendID = params.get('connection'),
        friend_playlist_id = params.get('id'),
        sessionType = '';
    if (!friendID) {
      sessionType = 'mine';
      friendID = localStorage.getItem('friendID');
      if (!friendID) {
        friendID = 0;
      }
    }

    //we zero-out the default values for our props
    this.state = {
      isLoading: true,
      isLoggedIn: false,
      username: 'Guest',
      userid: '',
      avatar: '',
      isSupporter: '',
      supporterBadge: '',
      adminBadge: '',
      modBadge: '',
      magicBadge: '',
      contributorBadge: '',
      xp: '',
      xpProgress: '',
      points: '',
      coins: '',
      friends: '',
      playlist: '',
      tracks: '',
      playlistName: '',
      playlistTitle: '',
      catalog: '',
      friendID: friendID
    };
    //this.createUser = this.createUser.bind(this);
    this.renderTracks = this.renderTracks.bind(this);
    this.addSong = this.addSong.bind(this);
    this.removeSong = this.removeSong.bind(this);
    this.rateSong = this.rateSong.bind(this);
    this.playSong = this.playSong.bind(this);
    this.showChat = this.showChat.bind(this);
    this.updatePlaylist = this.updatePlaylist.bind(this);
    this.renderCatalog = this.renderCatalog.bind(this);
    this.startSync = this.startSync.bind(this);

    if (friendID && sessionType !== 'mine') {
      this.updatePlaylist('custom', 'friend', friendID, friend_playlist_id);
    } else {
      if (localStorage.getItem('userid')) {
        if (playlistName) {
          this.updatePlaylist(playlistName);
        } else {
          this.updatePlaylist('favorites');
        }
      }
    }
  }

  renderCatalog(data) {
    let trackList = data.map((track, index) => {
      return (
        <tr key={track.id} data-id={track.id}>
          <td>
            {track.artist}
          </td>
          <td data-order={track.added_sort}>
            {track.added}
          </td>
          <td>
            <button type="button" className="btn btn-default dropdown-toggle justify-content-center align-self-center" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
            </button>
            <div className="dropdown-menu">
              <button className="dropdown-item add-song" onClick={() => {this.addSong(track.id)}} data-toggle="modal" data-target=".modal"><i className="fa fa-plus" aria-hidden="true"></i> Add</button>
              {track.custom ? <button className="dropdown-item add-song" onClick={() => {this.removeSong(track.id)}} data-toggle="modal" data-target=".modal"><i className="fa fa-minus" aria-hidden="true"></i> Remove</button> : ''}
              <button className="dropdown-item rate-song" onClick={() => {this.rateSong(track.id)}} data-toggle="modal" data-target=".modal"><i className="fa fa-star" aria-hidden="true"></i> Rate</button>
            </div>
            <span>{track.title}</span>
          </td>
        </tr>
      );
    });
    return trackList;
  }

  showChat() {
    $('#chat-box').toggleClass('show');
    ReactGA.event({category: 'Jukebox', action: 'Chat drawer toggle'});
  }

  /*createUser() {
    chatkit.createUser({
      id: this.state.currentUsername,
      name: this.state.currentUsername
    })
    .then((currentUser) => {
      this.setState({
          currentUsername: this.state.currentUsername,
          currentId: this.state.currentUsername,
          currentView: 'ChatApp'
      })
    }).catch((err) => {
      if(err.status === 400) {
        this.setState({
            currentUsername: this.state.currentUsername,
            currentId: this.state.currentUsername,
            currentView: 'ChatApp'
        })
      } else {
        console.log(err.status);
      }
    });
    console.log('user created');
  }*/

  addSong(songID) {
    //grab custom playlist names and ids
    let customPlaylistsAddSong = '';
    $('.playlist.user-playlist').each(function(){
      let customName = $(this).data('name'),
          customID = $(this).data('id');
          customPlaylistsAddSong = customPlaylistsAddSong+'<option value="'+customID+'">'+customName+'</option>';
    });
    let songName = $('.track-list [data-id='+songID+'] span').text();
    $('.modal .modal-title').html('<i class="fa fa-music" aria-hidden="true"></i> Add Song');
    $('.modal .modal-body').html('<p><strong>'+songName+'</strong></p><form><div class="form-group"><select id="add-song" class="form-control"><option value="">Choose a playlist</option><option value="0">Favorites</option>'+customPlaylistsAddSong+'</select></form><br /><div class="add-new-playlist"><p><strong>Create a New Playlist</p><input type="text" id="new-playlist" class="form-control"></div><div class="alert alert-danger" role="alert" style="display: none; margin: 10px 0 0 0;"></div>');
    $('.modal .modal-footer').html('<button type="button" class="btn btn-secondary close-add-song" data-dismiss="modal">Close</button><button type="button" class="btn btn-primary save-add-song">Add</button>');
    $('#add-song').on('change', function(){
      $('.modal .modal-body .alert').slideUp();
    });
    $('.save-add-song').on('click touchend', function(){
      let option = $('#add-song option:selected').val(),
          newPlaylist = $('#new-playlist').val();
      if (!option && !newPlaylist) { //no playlist or create new playlist
        $('.modal .modal-body .alert').html('Please choose a playlist.').slideDown();
        ReactGA.event({category: 'Jukebox', action: 'User must select a playlist when adding a song'});
      } else if (option && newPlaylist) { //playlist and create new playlist
        //we need to tell them to choose one
        $('.modal .modal-body .alert').html('You selected a playlist and typed in a new playlist.<br />Please choose only one.').slideDown();
        ReactGA.event({category: 'Jukebox', action: 'User chose a playlist and typed in a new one'});
      } else if (!option && newPlaylist) { //no playlist and create new playlist
        //create new playlist then add this song to it
        let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
        if (format.test(newPlaylist) === false && newPlaylist.length <= 40) {
          let formData = new FormData();
          formData.append('name', newPlaylist);
          formData.append('task', 'createPlaylist');
          formData.append('userid', localStorage.getItem('userid'));
          formData.append('auth_token', localStorage.getItem('auth_token'));
          return fetch('https://www.d-cot.com/web-api/', {
            method: 'POST',
            mode: 'cors',
            headers:{
              'Access-Control-Allow-Origin':'*'
            },
            body: formData
            })
            .then((response) => response.json())
            .then((responseJson) => {
              let data = responseJson;
              if (data) {
                if (data[0].status === 'success') {
                  let formData = new FormData();
                  formData.append('songName', songName);
                  formData.append('songID', songID);
                  formData.append('playlistID', data[0].playlistID);
                  formData.append('task', 'addSong');
                  formData.append('userid', localStorage.getItem('userid'));
                  formData.append('auth_token', localStorage.getItem('auth_token'));
                  return fetch('https://www.d-cot.com/web-api/', {
                    method: 'POST',
                    mode: 'cors',
                    headers:{
                      'Access-Control-Allow-Origin':'*'
                    },
                    body: formData
                    })
                    .then((response) => response.json())
                    .then((responseJson) => {
                      let data = responseJson;
                      if (data) {
                        if (data[0].status === 'success') {
                          $('.modal .modal-footer button.save-add-song').html('Added <i class="fa fa-check" aria-hidden="true"></i>');
                          $('.modal .modal-footer button.close-add-song').prop('disabled',false);
                          $('.modal .modal-footer button.save-add-song, #add-song').prop('disabled',true);
                        } else if (data[0].status === 'dupe') {
                          $('.modal .modal-body .alert').html('This song is already in your playlist.').slideDown();
                          $('.modal .modal-footer button.save-add-song').html('Add');
                          $('.modal .modal-footer button.close-add-song').prop('disabled',false);
                        } else if (data[0].status === 'testing') {
                          $('.modal .modal-body .alert').html('During testing, we are not adding songs to playlists. Please close the window. Thanks!').slideDown();
                          $('.modal .modal-footer button.save-add-song').html('Add');
                          $('.modal .modal-footer button.close-add-song').prop('disabled',false);
                          $('.modal .modal-footer button.save-add-song, #add-song').prop('disabled',true);
                        } else {
                          $('.modal .modal-body .alert').html('There was an error. Please try again later.').slideDown();
                          $('.modal .modal-footer button.save-add-song').html('Add');
                          $('.modal .modal-footer button.close-add-song').prop('disabled',false);
                          $('.modal .modal-footer button.save-add-song, #add-song').prop('disabled',true);
                        }
                      }
                  })
                  .catch((error) => {
                    console.error(error);
                    $('#message .col-lg-12').html('<div class="alert alert-danger alert-dismissible fade show" role="alert"><div class="row"><div class="col-md-1 text-center"><img src="https://www.d-cot.com/wp-content/themes/dcot-child/images/emojis/stitch.png" width="50" alt="Stitch emoji"></div><div class="col-md-11">Stitch did something <strong>again</strong>! Sorry about that. Please close the <strong>Add Song</strong> window and try again later. If you continue to get an error, please <strong>let us know</strong> by <a href="https://www.d-cot.com/contact/" target="_blank"><strong>contacting us</strong></a>.</div><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
                    $('#message').fadeIn();
                    ReactGA.event({category: 'Error', action: 'Add song failed'});
                  });
                } else if (data[0].status === 'dupe') {
                  $('.modal .modal-body .alert').html('This playlist already exists.').slideDown();
                  $('.modal .modal-footer button.save-add-song').html('Add');
                  $('.modal .modal-footer button.close-add-song').prop('disabled',false);
                  ReactGA.event({category: 'Jukebox', action: 'Playlist already exists'});
                } else {
                  $('.modal .modal-body .alert').html('There was an error. Please try again later.').slideDown();
                  $('.modal .modal-footer button.save-add-song').html('Add');
                  $('.modal .modal-footer button.close-add-song').prop('disabled',false);
                  $('.modal .modal-footer button.save-add-song, #add-song').prop('disabled',true);
                  ReactGA.event({category: 'Error', action: 'Adding song error'});
                }
              }
          })
          .catch((error) => {
            console.error(error);
            $('#message .col-lg-12').html('<div class="alert alert-danger alert-dismissible fade show" role="alert"><div class="row"><div class="col-md-1 text-center"><img src="https://www.d-cot.com/wp-content/themes/dcot-child/images/emojis/stitch.png" width="50" alt="Stitch emoji"></div><div class="col-md-11">Stitch did something <strong>again</strong>! Sorry about that. Please close the <strong>Add Song</strong> window and try again later. If you continue to get an error, please <strong>let us know</strong> by <a href="https://www.d-cot.com/contact/" target="_blank"><strong>contacting us</strong></a>.</div><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
            $('#message').fadeIn();
            ReactGA.event({category: 'Error', action: 'Add song failed'});
          });
        } else {
          $('.modal .modal-body .alert').html('Your playlist name either contains special characters or is greater than 40 characters. Please only user letters, numbers and spaces.').slideDown().delay(10000).slideUp();
          ReactGA.event({category: 'Jukebox', action: 'Unacceptable playlist name'});
        }
      } else {
        $('.modal .modal-footer button.close-add-song').prop('disabled',true);
        $('.modal .modal-footer button.save-add-song').html('Adding <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>');
        let formData = new FormData();
        formData.append('songName', songName);
        formData.append('songID', songID);
        formData.append('playlistID', option);
        formData.append('task', 'addSong');
        formData.append('userid', localStorage.getItem('userid'));
        formData.append('auth_token', localStorage.getItem('auth_token'));
        return fetch('https://www.d-cot.com/web-api/', {
          method: 'POST',
          mode: 'cors',
          headers:{
            'Access-Control-Allow-Origin':'*'
          },
          body: formData
          })
          .then((response) => response.json())
          .then((responseJson) => {
            let data = responseJson;
            if (data) {
              if (data[0].status === 'success') {
                $('.modal .modal-footer button.save-add-song').html('Added <i class="fa fa-check" aria-hidden="true"></i>');
                $('.modal .modal-footer button.close-add-song').prop('disabled',false);
                $('.modal .modal-footer button.save-add-song, #add-song').prop('disabled',true);
                ReactGA.event({category: 'Jukebox', action: 'Added a song'});
              } else if (data[0].status === 'dupe') {
                $('.modal .modal-body .alert').html('This song is already in your playlist.').slideDown();
                $('.modal .modal-footer button.save-add-song').html('Add');
                $('.modal .modal-footer button.close-add-song').prop('disabled',false);
                ReactGA.event({category: 'Jukebox', action: 'Add song duplicate'});
              } else if (data[0].status === 'testing') {
                $('.modal .modal-body .alert').html('During testing, we are not adding songs to playlists. Please close the window. Thanks!').slideDown();
                $('.modal .modal-footer button.save-add-song').html('Add');
                $('.modal .modal-footer button.close-add-song').prop('disabled',false);
                $('.modal .modal-footer button.save-add-song, #add-song').prop('disabled',true);
              } else {
                $('.modal .modal-body .alert').html('There was an error. Please try again later.').slideDown();
                $('.modal .modal-footer button.save-add-song').html('Add');
                $('.modal .modal-footer button.close-add-song').prop('disabled',false);
                $('.modal .modal-footer button.save-add-song, #add-song').prop('disabled',true);
                ReactGA.event({category: 'Error', action: 'Add song failed'});
              }
            }
        })
        .catch((error) => {
          console.error(error);
          $('#message .col-lg-12').html('<div class="alert alert-danger alert-dismissible fade show" role="alert"><div class="row"><div class="col-md-1 text-center"><img src="https://www.d-cot.com/wp-content/themes/dcot-child/images/emojis/stitch.png" width="50" alt="Stitch emoji"></div><div class="col-md-11">Stitch did something <strong>again</strong>! Sorry about that. Please close the <strong>Add Song</strong> window and try again later. If you continue to get an error, please <strong>let us know</strong> by <a href="https://www.d-cot.com/contact/" target="_blank"><strong>contacting us</strong></a>.</div><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
          $('#message').fadeIn();
          ReactGA.event({category: 'Error', action: 'Add song failed'});
        });
      }
    });
  }

  rateSong(songID) {
    let songName = $('.track-list [data-id='+songID+'] span').text();
    $('.modal .modal-title').html('<i class="fa fa-star" aria-hidden="true"></i> Rate Song');
    $('.modal .modal-body').html('<div class="fa fa-spinner fa-spin"></div>');
    $('.modal .modal-footer').html('<button type="button" class="btn btn-secondary close-rate-song" data-dismiss="modal">Close</button>');
    //fetch api for list of songs for playlist
    let formData = new FormData();
    formData.append('songName', songName);
    formData.append('songID', songID);
    formData.append('userid', localStorage.getItem('userid'));
    formData.append('auth_token', localStorage.getItem('auth_token'));
    formData.append('type', 'block');
    return fetch('https://www.d-cot.com/web-api/rate-song.php', {
      method: 'POST',
      mode: 'cors',
      headers:{
        'Access-Control-Allow-Origin':'*'
      },
      body: formData
      })
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;
        if (data) {
          $('.modal .modal-body').html('<p><strong>'+songName+'</strong></p><form><div class="form-group"></form>'+data[0].rating+'<div class="alert alert-danger" role="alert" style="display: none; margin-top: 10px;"></div>');
          $('ul.unit-rating li.vote').on('click touchstart', function() {
            let option = $(this).data('vote');
            if (!option) {
              $('.modal .modal-body .alert.alert-danger').html('Please choose a rating.').slideDown();
            } else {
              $('.modal .modal-footer button.close-rate-song').html('Rating <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>').prop('disabled',true);
              console.log(songName+','+option+','+songID+',5');
              let formData = new FormData();
              formData.append('songName', songName);
              formData.append('ncount', option);
              formData.append('id', songID);
              formData.append('units', 5);
              formData.append('userid', localStorage.getItem('userid'));
              formData.append('auth_token', localStorage.getItem('auth_token'));
              return fetch('https://www.d-cot.com/web-api/rate-song-action.php', {
              //return fetch('http://d-cot-platform.localhost/web-api/rate-song-action.php', {
                method: 'POST',
                mode: 'cors',
                headers:{
                  'Access-Control-Allow-Origin':'*'
                },
                body: formData
                })
                .then((response) => response.json())
                .then((responseJson) => {
                  let data = responseJson;
                  if (data) {
                    $('.modal .modal-footer button.close-rate-song').html('Close').prop('disabled',false);
                    $('.modal .modal-body .alert.alert-danger').html('Thank you for rating. You rated this song a '+option+'.').slideDown();
                  }
              })
              .catch((error) => {
                console.error(error);
                $('#message .col-lg-12').html('<div class="alert alert-danger alert-dismissible fade show" role="alert"><div class="row"><div class="col-md-1 text-center"><img src="https://www.d-cot.com/wp-content/themes/dcot-child/images/emojis/stitch.png" width="50" alt="Stitch emoji"></div><div class="col-md-11">Stitch did something <strong>again</strong>! Sorry about that. Please close the <strong>Rate Song</strong> window and try again later. If you continue to get an error, please <strong>let us know</strong> by <a href="https://www.d-cot.com/contact/" target="_blank"><strong>contacting us</strong></a>.</div><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
                $('#message').fadeIn();
                ReactGA.event({category: 'Error', action: 'Rate song failed'});
              });;
            }
          });
        }
    })
    .catch((error) => {
      console.error(error);
      $('#message .col-lg-12').html('<div class="alert alert-danger alert-dismissible fade show" role="alert"><div class="row"><div class="col-md-1 text-center"><img src="https://www.d-cot.com/wp-content/themes/dcot-child/images/emojis/stitch.png" width="50" alt="Stitch emoji"></div><div class="col-md-11">Stitch did something <strong>again</strong>! Sorry about that. Please close the <strong>Rate Song</strong> window and try again later. If you continue to get an error, please <strong>let us know</strong> by <a href="https://www.d-cot.com/contact/" target="_blank"><strong>contacting us</strong></a>.</div><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
      $('#message').fadeIn();
      ReactGA.event({category: 'Error', action: 'Rate song failed'});
    });
  }

  removeSong(songID) {
    //grab custom playlist names and ids
    let customPlaylistsAddSong = '';
    $('.playlist.custom-playlist').each(function(){
      let customName = $(this).data('name'),
          customID = $(this).data('id');
          customPlaylistsAddSong = customPlaylistsAddSong+'<option value="'+customID+'">'+customName+'</option>';
    });
    let songName = $('.track-list [data-id='+songID+'] span').text();
    $('.modal .modal-title').html('<i class="fa fa-music" aria-hidden="true"></i> Remove Song');
    $('.modal .modal-body').html('<p><strong>'+songName+'</strong></p>');
    $('.modal .modal-footer').html('<button type="button" class="btn btn-secondary close-remove-song" data-dismiss="modal">Close</button><button type="button" class="btn btn-primary save-remove-song">Remove</button>');
    $('#add-song').on('change', function(){
      $('.modal .modal-body .alert').slideUp();
    });
    $('.save-remove-song').on('click touchend', function(){
      $('.modal .modal-footer button.close-remove-song').prop('disabled',true);
      $('.modal .modal-footer button.save-remove-song').html('Removing <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>');
      let formData = new FormData();
      formData.append('songName', songName);
      formData.append('songID', songID);
      formData.append('playlistID', localStorage.getItem('curPlaylistID'));
      formData.append('task', 'removeSong');
      formData.append('userid', localStorage.getItem('userid'));
      formData.append('auth_token', localStorage.getItem('auth_token'));
      return fetch('https://www.d-cot.com/web-api/', {
        method: 'POST',
        mode: 'cors',
        headers:{
          'Access-Control-Allow-Origin':'*'
        },
        body: formData
        })
        .then((response) => response.json())
        .then((responseJson) => {
          let data = responseJson;
          if (data) {
            if (data[0].status === 'success') {
              $('.modal .modal-footer button.save-remove-song').html('Removed <i class="fa fa-check" aria-hidden="true"></i>');
              $('.modal .modal-footer button.close-remove-song').prop('disabled',false);
              $('.modal .modal-footer button.save-remove-song, #remove-song').prop('disabled',true);
              $('.track-list [data-id='+songID+']').remove();
            } else if (data[0].status === 'dupe') {
              $('.modal .modal-body .alert').html('This song is already removed from your playlist.').slideDown();
              $('.modal .modal-footer button.save-remove-song').html('Remove');
              $('.modal .modal-footer button.close-remove-song').prop('disabled',false);
            } else if (data[0].status === 'testing') {
              $('.modal .modal-body .alert').html('During testing, we are not removing songs from playlists. Please close the window. Thanks!').slideDown();
              $('.modal .modal-footer button.save-remove-song').html('Remove');
              $('.modal .modal-footer button.close-remove-song').prop('disabled',false);
              $('.modal .modal-footer button.save-remove-song, #remove-song').prop('disabled',true);
            } else {
              $('.modal .modal-body .alert').html('There was an error. Please try again later.').slideDown();
              $('.modal .modal-footer button.save-remove-song').html('Remove');
              $('.modal .modal-footer button.close-remove-song').prop('disabled',false);
              $('.modal .modal-footer button.save-remove-song, #remove-song').prop('disabled',true);
            }
          }
          ReactGA.event({category: 'Jukebox', action: 'Song was removed'});
      })
      .catch((error) => {
        console.error(error);
        $('#message .col-lg-12').html('<div class="alert alert-danger alert-dismissible fade show" role="alert"><div class="row"><div class="col-md-1 text-center"><img src="https://www.d-cot.com/wp-content/themes/dcot-child/images/emojis/stitch.png" width="50" alt="Stitch emoji"></div><div class="col-md-11">Stitch did something <strong>again</strong>! Sorry about that. Please close the <strong>Add Song</strong> window and try again later. If you continue to get an error, please <strong>let us know</strong> by <a href="https://www.d-cot.com/contact/" target="_blank"><strong>contacting us</strong></a>.</div><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
        $('#message').fadeIn();
        ReactGA.event({category: 'Error', action: 'Remove song failed'});
      });
    });
  }

  playSong(index,songID) {
    $('.audio_info').hide();
    $('.list-group li').each(function(){
      $(this).removeClass('active');
    });
    $('.list-group li[data-id='+songID+']').addClass('active');
    this.refs.player.refs.audioPlayer.setState({
      currentTrackIndex: index
    });
    this.refs.player.refs.audioPlayer.currentTrackIndex = index;
    this.refs.player.audioElement.src = this.state.playlist[index].url;
    setTimeout(() =>
      this.refs.player.refs.audioPlayer.setState({
        activeTrackIndex: index
      })
    , 1000);
    setTimeout(() =>
      $('.audio_info').fadeIn()
    , 1000);
    ReactGA.event({category: 'Jukebox', action: 'Playback was started'});
  }

  renderTracks(data) {
    let trackList = data.map((track, index) => {
      if (index === 0) {
        return (
          <li key={track.id} data-id={track.id} className="list-group-item list-group-item-action d-flex h-100 active">
            <button type="button" className="btn btn-default dropdown-toggle justify-content-center align-self-center" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
            </button>
            <div className="dropdown-menu">
              <button className="dropdown-item add-song" onClick={() => {this.addSong(track.id)}} data-toggle="modal" data-target=".modal"><i className="fa fa-plus" aria-hidden="true"></i> Add</button>
              {track.custom ? <button className="dropdown-item add-song" onClick={() => {this.removeSong(track.id)}} data-toggle="modal" data-target=".modal"><i className="fa fa-minus" aria-hidden="true"></i> Remove</button> : ''}
              <button className="dropdown-item rate-song" onClick={() => {this.rateSong(track.id)}} data-toggle="modal" data-target=".modal"><i className="fa fa-star" aria-hidden="true"></i> Rate</button>
              <button className="dropdown-item start-sync-session" onClick={() => {this.startSync(track.id)}}><i class="fa fa-users" aria-hidden="true"></i> Start D-Sync Session</button>
            </div>
            <span onClick={() => {this.playSong(index,track.id)}}>{track.title}</span>
          </li>
        );
      } else {
        return (
          <li key={track.id} data-id={track.id} className="list-group-item list-group-item-action d-flex h-100">
            <button type="button" className="btn btn-default dropdown-toggle justify-content-center align-self-center" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
            </button>
            <div className="dropdown-menu">
              <button className="dropdown-item add-song" onClick={() => {this.addSong(track.id)}} data-toggle="modal" data-target=".modal"><i className="fa fa-plus" aria-hidden="true"></i> Add</button>
              {track.custom ? <button className="dropdown-item add-song" onClick={() => {this.removeSong(track.id)}} data-toggle="modal" data-target=".modal"><i className="fa fa-minus" aria-hidden="true"></i> Remove</button> : ''}
              <button className="dropdown-item rate-song" onClick={() => {this.rateSong(track.id)}} data-toggle="modal" data-target=".modal"><i className="fa fa-star" aria-hidden="true"></i> Rate</button>
              <button className="dropdown-item start-sync-session" onClick={() => {this.startSync(track.id)}}><i class="fa fa-users" aria-hidden="true"></i> Start D-Sync Session</button>
            </div>
            <span onClick={() => {this.playSong(index,track.id)}}>{track.title}</span>
          </li>
        );
      }
    });
    return trackList;
    ReactGA.event({category: 'Jukebox', action: 'Tracklist loaded'});
  }

  startSync(songID) {
    let songName = $('.track-list [data-id='+songID+'] span').text();
    $('.modal .modal-title').html('<i class="fa fa-music" aria-hidden="true"></i> D-Sync<sup>&reg;</sup>');
    $('.modal .modal-body').html('<div class="alert alert-danger" role="alert" style="display: none; margin: 10px 0 0 0;"></div><div class="row"><div class="col-md-6 col-sm-12"><h6>Listeners</h4><ul class="list-group list-group-flush" id="sync-listeners"></ul></div><div class="col-md-6 col-sm-12"><h6>Track List <button type="button" class="add-sync-track"><i class="fa fa-plus-circle" aria-hidden="true"></i></button></h4><ul class="list-group list-group-flush px-0 mx-0" id="sync-tracks"><li class="px-0 mx-0" data-songID='+songID+'>'+songName+'</li></ul></div></div>');
    $('.modal .modal-footer').html('<button type="button" class="btn btn-secondary close-sync" data-dismiss="modal">Cancel</button><button type="button" class="btn btn-primary sync-invite">Send Invite</button><button type="button" class="btn btn-success start-sync">Start</button>');
    $('.modal').addClass('sync');
    $('.modal').modal('show');
  }

  updatePlaylist(playlistName, type, friend_id, friend_playlist_id) {
    $('#loading-modal').show();
    if (type === 'friend') {
      var playlistID = friend_playlist_id;
      //get friend name + playlist name
      setTimeout(function(){
        let formData = new FormData();
        formData.append('friendID', friend_id);
        formData.append('playlistID', friend_playlist_id);
        formData.append('userid', localStorage.getItem('userid'));
        formData.append('auth_token', localStorage.getItem('auth_token'));
        //fetch api for list of songs for playlist
        return fetch('https://www.d-cot.com/web-api/friend-playlist-info.php', {
          method: 'POST',
          mode: 'cors',
          headers:{
            'Access-Control-Allow-Origin':'*'
          },
          body: formData
          })
          .then((response) => response.json())
          .then((responseJson) => {
            let data = responseJson;
            if (data) {
              var friendUsername = data[0].friendUsername,
                  friendPlaylistName = data[0].playlistName;
              $('div.playlist-names a.list-group-item.playlist').each(function(){
                $(this).removeClass('active');
              });
              $('div.playlist-names').prepend('<a class="list-group-item divider my-playlists">'+friendUsername+'\'s Playlist</a><a class="list-group-item playlist d-flex justify-content-between align-items-center active" data-playlist="custom" data-name="'+friendPlaylistName+'">'+friendPlaylistName+'</a>');
            }
        })
        .catch((error) => {
          console.error(error);
        });
      }, 2000);
    } else {
      var playlistID = localStorage.getItem('curPlaylistID'),
          playlistTitle = localStorage.getItem('curPlaylistTitle'),
          playlistName = localStorage.getItem('curPlaylistName');
      if (!playlistName) {
        var playlistName = 'favorites';
      }
    }

    let formData = new FormData();
    formData.append('playlistName', playlistName);
    formData.append('playlistID', playlistID);
    formData.append('userid', localStorage.getItem('userid'));
    formData.append('auth_token', localStorage.getItem('auth_token'));
    //fetch api for list of songs for playlist
    return fetch('https://www.d-cot.com/web-api/playlist.php', {
      method: 'POST',
      mode: 'cors',
      headers:{
        'Access-Control-Allow-Origin':'*'
      },
      body: formData
      })
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;
        if (data) {
          this.setState({
            playlist: data,
            tracks: this.renderTracks(data),
            playlistName: playlistName,
            playlistTitle: playlistTitle
          });
          /*setTimeout(function(){
            let sortableElem = document.getElementById('track-group'),
                sortable = Sortable.create(sortableElem, {
                  scroll: true,
                  /*onEnd: function (evt) {
                    console.log('moved');
                    let order = sortable.toArray();
                    console.log(order);
                  }
            });
            if (playlistName !== 'custom') {
              sortable.destroy();
              console.log('no');
            }
          }, 1000);*/
          window.scrollTo(0,0);
        }
        $('#loading-modal').hide();
        ReactGA.event({category: 'Jukebox', action: 'Playlist changed to '+playlistName});
    })
    .catch((error) => {
      console.error(error);
    });
  }

  UNSAFE_componentWillMount() {
    let userid = localStorage.getItem('userid'),
        auth_token = localStorage.getItem('auth_token');
    //run a fetch to grab the user data and update the states
    if (userid) {
      //fetch user data
      let formData = new FormData();
      formData.append('userid', userid);
      formData.append('auth_token', auth_token);
      return fetch('https://www.d-cot.com/web-api/user.php', {
        method: 'POST',
        mode: 'cors',
        headers:{
          'Access-Control-Allow-Origin':'*'
        },
        body: formData
        })
        .then((response) => response.json())
        .then((responseJson) => {
          let data = responseJson;
          if (data[0].userid === 'Token error') {
            //alert('You are logged in from another device. We will log you out here. Feel free to log in from here again.');
            localStorage.removeItem('userid');
            localStorage.removeItem('auth_token');
            localStorage.removeItem('curPlaylist');
            localStorage.removeItem('curPlaylistTitle');
            localStorage.removeItem('curPlaylistID');
            localStorage.removeItem('volLevel');
            this.setState({
              isLoading: false
            });
            $('#message .col-lg-12').html('<div class="alert alert-danger alert-dismissible fade show" role="alert"><div class="row"><div class="col-md-12 text-center">You are logged in from another device so we signed you out here. Feel free to log in again.</div><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
            $('#message').fadeIn();
          } else if (data[0].supporter !== 'supporter') {
            //alert('At this time, only supporters can use this app.');
            localStorage.removeItem('userid');
            localStorage.removeItem('auth_token');
            localStorage.removeItem('curPlaylist');
            localStorage.removeItem('curPlaylistTitle');
            localStorage.removeItem('curPlaylistID');
            localStorage.removeItem('volLevel');
            this.setState({
              isLoading: false
            });
            $('#message .col-lg-12').html('<div class="alert alert-danger alert-dismissible fade show" role="alert"><div class="row"><div class="col-md-12 text-center">At this time, only supporters can use this app.</div><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>');
            $('#message').fadeIn();
            ReactGA.event({category: 'Jukebox', action: 'User, '+userid+', tried logging into the web app but is not a supporter'});
          } else {
            if (data[0].userid !== 'Token error') {
              if (data[0].adminBadge) {
                this.setState({
                  adminBadgeCode: <div className="alert alert-warning orange text-center badge">ADMIN</div>
                });
              }
              if (data[0].modBadge) {
                this.setState({
                  modBadgeCode: <div className="alert alert-warning orange text-center badge">MOD</div>
                });
              }
              if (data[0].magicBadge) {
                this.setState({
                  magicBadgeCode: <div className="alert alert-warning orange text-center badge">MAGIC MAKER</div>
                });
              }
              if (data[0].contributorBadge) {
                this.setState({
                  contributorBadgeCode: <div className="alert alert-warning orange text-center badge">CONTRIBUTOR</div>
                });
              }
              if (data[0].supporterBadge) {
                this.setState({
                  supporterBadgeCode: <div className="alert alert-success green text-center badge">SUPPORTER</div>,
                  avatarSrc: <img alt="avatar" className="avatar rounded img-fluid supporter" src={data[0].avatar}/>
                });
              } else {
                this.setState({
                  avatarSrc: <img alt="avatar" className="avatar rounded img-fluid" src={data[0].avatar}/>
                });
              }

              this.setState({
                isLoading: false,
                isLoggedIn: true,
                auth_token: localStorage.getItem('auth_token'),
                username: data[0].username,
                userid: data[0].userid,
                avatar: this.state.avatarSrc,
                isSupporter: data[0].supporter,
                supporterBadge: this.state.supporterBadgeCode,
                adminBadge: this.state.adminBadgeCode,
                modBadge: this.state.modBadgeCode,
                contributorBadge: this.state.contributorBadgeCode,
                xp: data[0].xp,
                xpProgress: data[0].xpProgress,
                points: data[0].points,
                friends: '',
                coins: '',
                nextTrip: data[0].nextTrip,
                currentUsername: data[0].username,
                currentId: data[0].username
              });
              console.log('Welcome home!');
              ReactGA.event({category: 'Jukebox', action: 'User, '+data[0].userid+', loaded the web app'})
            }
          }
      })
      .catch((error) => {
        console.error(error);
      });
    } else {
        this.setState({
          isLoading: false
        });
    }
  }

  componentDidMount() {
    $('[data-toggle="popover"]').popover();
    let randomMessage = Math.floor((Math.random() * 7) + 1);
		if (randomMessage === 1) {
			$('#loading-message').text('Creating a magical experience for you...').fadeIn();
		} else if (randomMessage === 2) {
			$('#loading-message').text('Por favor mantengase alejado de las puertas...').fadeIn();
		} else if (randomMessage === 3) {
			$('#loading-message').text('Sprinkling pixie dust...').fadeIn();
		} else if (randomMessage === 4) {
			$('#loading-message').text('Off to Neverland...').fadeIn();
		} else if (randomMessage === 5) {
			$('#loading-message').text('Your future is just about ready...').fadeIn();
		} else if (randomMessage === 6) {
			$('#loading-message').text('Building you a better tomorrow...').fadeIn();
		} else if (randomMessage === 7) {
			$('#loading-message').text('Your songs are just a dream away...').fadeIn();
		} else {
			$('#loading-message').text('Looking for Pooh...').fadeIn();
		}
    this.setState({
        currentView: 'ChatApp'
    });

    if (localStorage.getItem('userid')) {
      let formData = new FormData();
      formData.append('userid', localStorage.getItem('userid'));
      formData.append('auth_token', localStorage.getItem('auth_token'));
      return fetch('https://www.d-cot.com/web-api/catalog.php', {
        method: 'POST',
        mode: 'cors',
        headers:{
          'Access-Control-Allow-Origin':'*'
        },
        body: formData
        })
        .then((response) => response.json())
        .then((responseJson) => {
          let data = responseJson;
          if (data) {
            this.setState({
              catalog: this.renderCatalog(data)
            });
          }
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }

  render() {
    let view = '';
    if (this.state.currentView === "ChatMessage") {
      view = <ChatMessage changeView={this.changeView}/>
    } else if (this.state.currentView === "ChatApp") {
      view = <ChatApp currentId={this.state.username} />
    }
    const isLoggedIn = this.state.isLoggedIn;
    if (this.state.isLoading) {
      return (
        <div>
          <div className="container-fluid d-flex align-items-center" id="loading">
            <div className="row w-100">
              <div className="col-sm-12 text-center">
                <h3 id="loading-message">Hold on to those hats and glasses...</h3>
                <br />
                <div className="fa fa-spinner fa-spin"></div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (this.state.isError) {
      return (
        <div>
          <h3 className="text-center"><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> We ran into an error.</h3>
        </div>
      );
    }

    return (
      <div>
        <div id="app">
          {isLoggedIn ? (
            <div>
              <Router>
                <div>
                  <Menu
                    isLoggedIn={this.state.isLoggedIn}
                    username={this.state.username}
                    isSupporter={this.state.isSupporter}
                    avatar={this.state.avatar}
                    addSong={this.addSong}
                  />
                  <Route path="/"
                    exact
                    render={(props)=><Home
                      isLoggedIn={this.state.isLoggedIn}
                      auth_token={this.state.auth_token}
                      userid={this.state.userid}
                      isSupporter={this.state.isSupporter}
                      tracks={this.state.tracks}
                    />}
                  />
                  <Route
                    path="/catalog/"
                    render={(props)=><MusicCatalog
                      isLoggedIn={this.state.isLoggedIn}
                      auth_token={this.state.auth_token}
                      username={this.state.username}
                      userid={this.state.userid}
                      avatar={this.state.avatar}
                      isSupporter={this.state.isSupporter}
                      supporterBadge={this.state.supporterBadge}
                      adminBadge={this.state.adminBadge}
                      modBadge={this.state.modBadge}
                      contributorBadge={this.state.contributorBadge}
                      xp={this.state.xp}
                      xpProgress={this.state.xpProgress}
                      points={this.state.points}
                      coins={this.state.coins}
                      friends={this.state.friends}
                      nextTrip={this.state.nextTrip}
                      addSong={this.addSong}
                      removeSong={this.removeSong}
                      catalog={this.state.catalog}
                    />}
                  />
                  <Route
                    path="/logout/"
                    render={(props)=><Logout
                      isLoggedIn={this.state.isLoggedIn}
                      auth_token={this.state.auth_token}
                      username={this.state.username}
                      userid={this.state.userid}
                      avatar={this.state.avatar}
                      isSupporter={this.state.isSupporter}
                      supporterBadge={this.state.supporterBadge}
                      adminBadge={this.state.adminBadge}
                      modBadge={this.state.modBadge}
                      contributorBadge={this.state.contributorBadge}
                      xp={this.state.xp}
                      xpProgress={this.state.xpProgress}
                      points={this.state.points}
                      coins={this.state.coins}
                      friends={this.state.friends}
                      nextTrip={this.state.nextTrip}
                    />}
                  />
                  <Playlists
                    isLoggedIn={this.state.isLoggedIn}
                    auth_token={this.state.auth_token}
                    userid={this.state.userid}
                    isSupporter={this.state.isSupporter}
                    updatePlaylist={this.updatePlaylist}
                    friendID={this.state.friendID}
                  />
                  <Player
                    isLoggedIn={this.state.isLoggedIn}
                    auth_token={this.state.auth_token}
                    userid={this.state.userid}
                    isSupporter={this.state.isSupporter}
                    playlist={this.state.playlist}
                    timeListened={this.state.timeListened}
                    updatePlaylist={this.updatePlaylist}
                    ref="player"
                  />
                  <Sync
                    isLoggedIn={this.state.isLoggedIn}
                    auth_token={this.state.auth_token}
                    userid={this.state.userid}
                    isSupporter={this.state.isSupporter}
                    ref="sync"
                  />
                  <div id="chat-box">
                    <div className="toggle-chat" onClick={() => {this.showChat()}}><i className="fa fa-comments-o" aria-hidden="true"></i></div>
                    {view}
                  </div>
                  <div>
                    <div className="modal fade" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
                      <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title"> </h5>
                          </div>
                          <div className="modal-body text-center">
                          </div>
                          <div className="modal-footer">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="container-fluid align-items-center" id="loading-modal">
                      <div className="row w-100">
                        <div className="col-sm-12 text-center">
                          <div className="fa fa-spinner fa-spin"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Router>
              <a href="http://offtoneverland.com/?cpe=Y3A9R2VuZXJhbCtMaW5rJnM9RC1Db1Q%3D" rel="noopener noreferrer" target="_blank">
                <img className="sponsor" src={logoOTN} alt="Off to Neverland Travel"/>
              </a>
            </div>
          ) : (
            <div>
              <Login />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default App;
