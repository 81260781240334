import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import '../Login/Login.css';
import $ from 'jquery';
import ReactGA from 'react-ga';
ReactGA.pageview(window.location.pathname + window.location.search);

class Logout extends Component {
  logout() {
    ReactGA.event({category: 'Jukebox', action: 'User, '+this.props.userid+', logged out of the jukebox'});
    localStorage.removeItem('userid');
		localStorage.removeItem('auth_token');
    localStorage.removeItem('curPlaylist');
    localStorage.removeItem('curPlaylistTitle');
    localStorage.removeItem('curPlaylistID');
    localStorage.removeItem('volLevel');
    window.location = '/';
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  render() {
    return (
      <div id="login">
        <div id="main">
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-4 col-md-3 col-12"></div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="shadow-sm p-3 mb-5 bg-white rounded text-center">
                  <h5>Hello, {this.props.username}</h5>
                  <p>It looks like you want to log out. Are you sure? If so, click the button below.<br />See ya real soon!</p>
                  <button className="btn btn-primary btn-block" onClick={(event) => this.logout()}><i className="fa fa-sign-out" aria-hidden="true"></i> Log Out</button>
                  <NavLink className="btn btn-primary btn-block" to="/">Go Back and Stay Logged In</NavLink>
                </div>
              </div>
              <div className="col-lg-4 col-md-3 col-12"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Logout;
