import React, { Component } from 'react';
import './ChatApp.css';
import { ChatManager, TokenProvider } from '@pusher/chatkit-client';
import MessageList from './../../components/MessageList/MessageList';
import Input from './../../components/Input/Input';
import TypingIndicator from './../../components/TypingIndicator/TypingIndicator';
import $ from 'jquery';

class ChatApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listenerList: ''
    }
    this.listenerList = this.listenerList.bind(this);
  }

  listenerList() {
    //get listener list
    let formData = new FormData();
    formData.append('userid', localStorage.getItem('userid'));
    formData.append('auth_token', localStorage.getItem('auth_token'));
    return fetch('https://www.d-cot.com/web-api/listener-list.php', {
      method: 'POST',
      mode: 'cors',
      headers:{
        'Access-Control-Allow-Origin':'*'
      },
      body: formData
      })
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;
        if (data) {
          this.setState({
            listenerList: this.renderListeners(data)
          });
        }

        $('ul#listeners li').on('click', function(e) {
          let inputText = document.getElementById('chat-message-input'),
              username = $(this).data('username');
          inputText.value += '@'+username+' ';
          inputText.focus();
        });
    })
    .catch((error) => {
      console.error(error);
    });
  }

  sortList(listName) {
    var list, i, switching, b, shouldSwitch;
    list = document.getElementById(listName);
    switching = true;
    /* Make a loop that will continue until
    no switching has been done: */
    while (switching) {
      // start by saying: no switching is done:
      switching = false;
      b = list.getElementsByTagName("LI");
      // Loop through all list-items:
      for (i = 0; i < (b.length - 1); i++) {
        // start by saying there should be no switching:
        shouldSwitch = false;
        /* check if the next item should
        switch place with the current item: */
        if (b[i].innerHTML.toLowerCase() > b[i + 1].innerHTML.toLowerCase()) {
          /* if next item is alphabetically
          lower than current item, mark as a switch
          and break the loop: */
          shouldSwitch = true;
          break;
        }
      }
      if (shouldSwitch) {
        /* If a switch has been marked, make the switch
        and mark the switch as done: */
        b[i].parentNode.insertBefore(b[i + 1], b[i]);
        switching = true;
      }
    }
  }

  renderListeners(data) {
    let listenerList = data.map((user, index) => {
      if (
        user.username === 'steve' ||
        user.username === 'Eric' ||
  			user.username === 'eric' ||
  			user.username === 'nascfan' ||
  			user.username === 'Nascfan' ||
  			user.username === 'Bryce' ||
  			user.username === 'bryce' ||
  			user.username === 'eeyorepoohfan' ||
  			user.username === 'starbee'
      ) {
        return (
          <li key={user.userid} data-id={user.userid} data-username={user.username} title={user.track} data-sort="1">
            <span style={{ color: '#ff8519' }}>{user.username}</span>
          </li>
        );
      } else if (user.username === 'chelle' || user.username === 'Chelle') {
        return (
          <li key={user.userid} data-id={user.userid} data-username={user.username} title={user.track} data-sort="2">
            <span style={{ color: '#fd4296' }}>{user.username}</span>
          </li>
        );
      } else if (user.supporter === '1') {
         return (
           <li key={user.userid} data-id={user.userid} data-username={user.username} title={user.track} data-sort="3">
             <span style={{ color: '#77af12' }}>{user.username}</span>
           </li>
         );
      } else {
        return (
          <li key={user.userid} data-id={user.userid} data-username={user.username} title={user.track} data-sort="4">
            {user.username}
          </li>
        );
      }
    });
    return listenerList;
  }

  componentDidMount() {
    let getListenerList = setInterval(this.listenerList, 240000);
    this.listenerList();
  }

  render() {
    return (
      <div id="chat-app">
        <ul id="message-list" className="message-list">
          <li><h4 className="message-sender">steve</h4><p>New chat coming soon. 💭</p></li>
        </ul>
        <ul id="user-list" className="list-group list-group-flush">
          <h6 className="mb-0" data-toggle="collapse" href="ul#listeners" role="button" aria-expanded="false" aria-controls="collapseListeners">Listeners</h6>
          <ul id="listeners" className="px-0 py-0 collapse show">
            {this.state.listenerList}
          </ul>
        </ul>
      </div>
    )
  }
}

export default ChatApp;
