import React, { Component } from 'react';
//import AudioPlayer from 'react-responsive-audio-player';
import { MediaPlayer } from '@cassette/player';
//import './AudioPlayer.css';
import './MediaPlayer.css';
import './Player.css';
import $ from 'jquery';
import ReactGA from 'react-ga';

class Player extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeListened: 0
    };
  }

  componentDidMount() {
    //set volume
    let volLevel = localStorage.getItem('volLevel');
    if (volLevel !== '') {
      document.querySelector("video").volume = volLevel;
    } else {
      document.querySelector("video").volume = '.5';
    }
    $('[data-toggle="tooltip"]').tooltip();
  }

  render() {
    let timeListened = 0;
    function loadStart(e) {
      setTimeout(function() {
        let $curSong = $('.cassette .cassette__control_bar').attr('title');
        $('#tracks ul li').removeClass('active');
        $('#tracks ul li span').each(function(){
          if ($(this).text() === $curSong) {
            $(this).parent().addClass('active');
          }
        });
        trackTime('loadstart',$curSong);
        console.log('tracked');
      }, 500);
    }
    function updateVolLevel(e) {
      let $volume = document.querySelector("video").volume;
      if ($volume === 0) {
        $volume = '.5';
        document.querySelector("video").volume = $volume;
      }
      localStorage.setItem('volLevel', $volume);
      console.log('set vol to '+$volume);
    }
    function trackTime(action,songName) {
      if (action === 'loadstart') {
        //record listening time
        console.log(Math.round(timeListened));
        let formData = new FormData();
        formData.append('songName', songName);
        formData.append('timeListened', timeListened);
        formData.append('userid', localStorage.getItem('userid'));
        formData.append('auth_token', localStorage.getItem('auth_token'));
        //fetch api for list of songs for playlist
        return fetch('https://www.d-cot.com/web-api/track.php', {
          method: 'POST',
          mode: 'cors',
          headers:{
            'Access-Control-Allow-Origin':'*'
          },
          body: formData
          })
          .then((response) => response.json())
          .then((responseJson) => {
            let data = responseJson;
            if (data) {
              if (data[0].tracked === 'Token error') {
                console.log('Token error!');
                localStorage.removeItem('userid');
                localStorage.removeItem('auth_token');
                localStorage.removeItem('curPlaylist');
                localStorage.removeItem('curPlaylistID');
                window.location = '/';
              } else {
                timeListened = 0;
                ReactGA.event({category: 'Jukebox', action: 'Song was tracked'})
              }
            }
        })
        .catch((error) => {
          console.error(error);
        });
      } else if (action === 'progress') {
        timeListened = (timeListened+1/4.4);
        //console.log(timeListened);
      }
      $('button.magic-playlist').on('click', function() {
        var $currentSongName = $('.cassette__media_info_marquee > div > div').text();
        localStorage.setItem('curPlaylist', $currentSongName);
        localStorage.setItem('curPlaylistID', $currentSongName);
        localStorage.setItem('curPlaylistName', 'magic');
        localStorage.setItem('curPlaylistTitle', 'Songs similar to '+$currentSongName);
      });
    }
    return (
      <div id="player">
        <button onClick={this.props.updatePlaylist} className="magic-playlist" data-playlist="magic" data-name="Magic Playlist"  data-toggle="tooltip" data-placement="bottom" title="Click to load a playlist similar to the current song">
          <img src="https://www.d-cot.com/wp-content/themes/dcot-child/images/emojis/dcot-logo.png" alt="Magic Playlist" />
        </button>
        <MediaPlayer
          ref="audioPlayer"
          playlist={this.props.playlist}
          controls={['playpause','backskip','forwardskip','shuffle','repeat','volume','progress']}
          mediaElementRef={
            elem => {
              this.audioElement = elem;
              elem.addEventListener('play', () => ReactGA.event({category: 'Jukebox', action: 'Playback was started'}));
              elem.addEventListener('pause', () => ReactGA.event({category: 'Jukebox', action: 'Playback was paused'}));
              elem.addEventListener('timeupdate', () => trackTime('progress'));
              elem.addEventListener('volumechange', (e) => updateVolLevel(e));
              elem.addEventListener('loadstart', (e) => loadStart(e));
            }
          }
        />
      </div>
    );
  }
}

export default Player;
