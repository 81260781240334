import React, { Component } from 'react';
import $ from 'jquery';
import './Playlists.css';
import ReactGA from 'react-ga';

class Playlists extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customPlaylists: '',
      customPlaylistHTML: '<button class="list-group-item divider my-playlists">My Playlists</button><button class="active list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="favorites" data-name="Favorites">Favorites</button>',
    }
    this.showPlaylists = this.showPlaylists.bind(this);
  }

  showPlaylists() {
    $('#playlists, #playlists .playlist-toggle .toggle').toggleClass('show');
    ReactGA.event({category: 'Jukebox', action: 'Playlist drawer toggle'});
  }

  UNSAFE_componentWillMount() {
    let formData = new FormData();
    formData.append('friendID', this.props.friendID);
    formData.append('userid', this.props.userid);
    formData.append('auth_token', this.props.auth_token);
    return fetch('https://www.d-cot.com/web-api/playlist-custom.php', {
      method: 'POST',
      mode: 'cors',
      headers:{
        'Access-Control-Allow-Origin':'*'
      },
      body: formData
      })
      .then((response) => response.json())
      .then((responseJson) => {
        let data = responseJson;
        if (data) {
          for (let key of Object.keys(data)) {
            let customPlaylistName = data[key],
                customPlaylistHTML = '';
            if (customPlaylistName.playlistCustomID.startsWith('00-')) {
              customPlaylistHTML = '<button class="list-group-item divider friend-playlists" data-playlist="custom" data-name="'+customPlaylistName.playlistCustomName+'" data-id="'+customPlaylistName.playlistCustomID+'">'+customPlaylistName.playlistCustomName+' <span class="clear-friend-list" title="Remove Friend\'s Playlists"><i class="fa fa-times"></i></span></button>';
            } else if (customPlaylistName.playlistCustomFriendID) {
              customPlaylistHTML = '<button class="list-group-item playlist custom-playlist d-flex justify-content-between align-items-center" data-playlist="custom-friend" data-name="'+customPlaylistName.playlistCustomName+'" data-id="'+customPlaylistName.playlistCustomID+'">'+customPlaylistName.playlistCustomName+'</button>';
            } else {
              customPlaylistHTML = '<button class="list-group-item playlist custom-playlist user-playlist d-flex justify-content-between align-items-center" data-playlist="custom" data-name="'+customPlaylistName.playlistCustomName+'" data-id="'+customPlaylistName.playlistCustomID+'">'+customPlaylistName.playlistCustomName+'</button>';
            }
            this.setState({
              customPlaylistHTML: this.state.customPlaylistHTML+customPlaylistHTML,
              customPlaylistData: data
            });
          }
        }
        $('.playlist-names').prepend(this.state.customPlaylistHTML);
        if (localStorage.getItem('curPlaylist') === 'custom') {
          $('#playlists button.active').removeClass('active');
          $('#playlists button[data-id='+localStorage.getItem('curPlaylistID')+']').addClass('active');
        }
        $('#playlists button.list-group-item span.clear-friend-list').on('click', function() {
          localStorage.removeItem('friendID');
          var uri = window.location.toString();
          if (uri.indexOf("?") > 0) {
            var clean_uri = uri.substring(0, uri.indexOf("?"));
            window.history.replaceState({}, document.title, clean_uri);
          }
          window.location.reload();
        });
        $('#playlists button.list-group-item.playlist').on('click', function() {
          let playlistName = $(this).data('playlist'),
              playlistTitle = $(this).data('name');
          $('#playlists button.active').removeClass('active');
          $(this).addClass('active');
          if (playlistName === 'custom') {
            let playlistID = $(this).data('id');
            localStorage.setItem('curPlaylistID', playlistID);
            //make a call to updatePlaylist
            var uri = window.location.toString();
          	if (uri.indexOf("?") > 0) {
        	    var clean_uri = uri.substring(0, uri.indexOf("?"));
        	    window.history.replaceState({}, document.title, clean_uri);
          	}
            window.location.reload();
          } else if (playlistName === 'custom-friend') {
            let friendID = localStorage.getItem('friendID');
            if (!friendID) {
              friendID = $('button.friend-playlists').data('id').substring(3);
              localStorage.setItem('friendID', friendID);
            }
            let playlistID = $(this).data('id');
            localStorage.setItem('curPlaylistID', playlistID);
            //make a call to updatePlaylist
            if (friendID) {
              //var uri = window.location.toString();
            	window.history.replaceState({}, document.title, '?connection='+friendID+'&id='+playlistID);
            }
            window.location.reload();
          } else if (playlistName === 'favorites') {
            //make a call to updatePlaylist
            window.location.reload();
          } else {
            localStorage.setItem('curPlaylistID', 0);
            var uri = window.location.toString();
          	if (uri.indexOf("?") > 0) {
        	    //var clean_uri = uri.substring(0, uri.indexOf("?"));
        	    window.history.replaceState({}, document.title, clean_uri);
          	}
          }
          localStorage.setItem('curPlaylist', playlistName);
          localStorage.setItem('curPlaylistTitle', playlistTitle);
          localStorage.setItem('curPlaylistName', playlistName);
          //$('.react-jinke-music-player-main .audio-lists-panel').removeClass('hide').addClass('show');
          $('#playlists, #playlists .playlist-toggle .toggle').toggleClass('show');
        });

        if (localStorage.getItem('curPlaylist')) {
          $('#playlists a.active').removeClass('active');
          if (localStorage.getItem('curPlaylist') === 'custom') {
              $('#playlists a[data-id='+localStorage.getItem('curPlaylistID')+']').addClass('active');
          } else {
              $('#playlists a[data-playlist='+localStorage.getItem('curPlaylist')+']').addClass('active');
          }
        }
    })
    .catch((error) => {
      console.error(error);
    });
  }

  render() {
    return (
      <div id="playlists" className="">
        <div onClick={this.showPlaylists} className="playlist-toggle">
          <i className="toggle ico-close fa fa-times" aria-hidden="true"></i>
          <i className="toggle ico-open show fa fa-music" aria-hidden="true"></i>
        </div>
        <div className="list-group list-group-flush">
          <div className="audio-lists-panel-header">
            <h4 className="title">Playlists</h4>
          </div>
          <div className="playlist-names">
            <button className="list-group-item divider">Compilations</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="newest" data-name="Newest Additions">Newest Additions</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="top25week" data-name="Top 25 Played Week">Top 25 Played Week</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="top25played" data-name="Top 25 Played Overall">Top 25 Played Overall</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="top25rated" data-name="Top 25 Rated">Top 25 Rated</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="random" data-name="Random 25">Random 25</button>
            <button className="list-group-item divider">Walt Disney World</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="magic_kingdom" data-name="Magic Kingdom">Magic Kingdom</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="epcot" data-name="Epcot">Epcot</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="hollywood_studios" data-name="Disney's Hollywood Studios">Disney's Hollywood Studios</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="animal_kingdom" data-name="Disney's Animal Kingdom">Disney's Animal Kingdom</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="typhoon_lagoon" data-name="Typhoon Lagoon">Typhoon Lagoon</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="blizzard_beach" data-name="Blizzard Beach">Blizzard Beach</button>
            <button className="list-group-item divider">Disneyland</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="disneyland_park" data-name="Disneyland Park">Disneyland Park</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="california_adventure" data-name="Disney's California Adventure">Disney's California Adventure</button>
            <button className="list-group-item playlist divider">Disney Around the World</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="disneyland_paris" data-name="Disneyland Paris">Disneyland Paris</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="hong_kong" data-name="Hong Kong Disneyland">Hong Kong Disneyland</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="tokyo_disney" data-name="Tokyo Disney Resort">Tokyo Disney Resort</button>
            <button className="list-group-item divider">Resorts</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="animal_kingdom_lodge" data-name="Animal Kingdom Lodge">Animal Kingdom Lodge</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="boardwalk" data-name="Disney's BoardWalk Inn">Disney's BoardWalk Inn</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="coronado_springs" data-name="Coronado Springs">Coronado Springs</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="disneyland_hotel" data-name="Disneyland Hotel">Disneyland Hotel</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="grand_floridian" data-name="Grand Floridian">Grand Floridian</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="old_key_west" data-name="Old Key West">Old Key West</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="polynesian" data-name="Polynesian">Polynesian</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="port_orleans" data-name="Port Orleans">Port Orleans</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="wilderness_lodge" data-name="Wilderness Lodge">Wilderness Lodge</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="resort_tv" data-name="Resort TV">Resort TV</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="transportation" data-name="Transportation">Transportation</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="yacht_beach" data-name="Transportation">Yacht &amp; Beach Club</button>
            <button className="list-group-item divider">Holidays</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="christmas" data-name="Christmas">Christmas</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="halloween" data-name="Halloween">Halloween</button>
            <button className="list-group-item divider">Mouse-ellaneous</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="disney_cruise_line" data-name="Disney Cruise Line">Disney Cruise Line</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="misc" data-name="Disney-Related Audio">Disney-Related Audio</button>
            <button onClick={this.props.updatePlaylist} className="list-group-item playlist d-flex justify-content-between align-items-center" data-playlist="monorail" data-name="Monorail">Monorail</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Playlists;
