import React, { Component } from 'react';
import Tracks from '../Tracks/Tracks';

class Home extends Component {
  render() {
    return (
      <div id="home">
        <Tracks
          isLoggedIn={this.props.isLoggedIn}
          auth_token={this.props.auth_token}
          userid={this.props.userid}
          isSupporter={this.props.isSupporter}
          tracks={this.props.tracks}
          rateSong={this.props.rateSong}
          addSong={this.props.addSong}
        />
      </div>
    );
  }
}

export default Home;
