import React, { Component } from 'react';
import './ChatMessage.css';

class ChatMessage extends Component {
  constructor(props) {
    super(props);
    this.changeView = this.changeView.bind(this);
  }

  changeView() {
    this.props.changeView('ChatApp');
  }

  render() {
      return (
        <div id="chat-message">
          <button className="chat-button btn btn-primary" onClick={this.changeView}>Send a message</button>
        </div>
      )
  }
}

export default ChatMessage;
