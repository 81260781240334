import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import './Login.css';
import $ from 'jquery';
import ReactGA from 'react-ga';
ReactGA.pageview(window.location.pathname + window.location.search);

class Login extends Component {
  constructor(props) {
      super(props);
      this.state = {
        validating: false,
        username: '',
        password: ''
      };
  }

  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
  }

  handleUserInput(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({[name]: value});
  }

  validate() {
    $('.alert').slideUp();
    $('button.login').html('Logging you in <div class="fa fa-spinner fa-spin"></div>');

    this.setState({
      validating: true
    });

    if (this.state.username && this.state.password) {
      let formData = new FormData();
      formData.append('type', 'login');
      formData.append('username', this.state.username);
      formData.append('password', this.state.password);
      return fetch('https://www.d-cot.com/web-api/login.php', {
        method: 'POST',
        mode: 'cors',
        headers:{
          'Access-Control-Allow-Origin':'*'
        },
        body: formData
        })
        .then((response) => response.json())
        .then((responseJson) => {
          let data = responseJson;
          if (data.response[0].msg === 'Username and Password do not match') {
            $('.alert').html('<strong>Oh dear!</strong><br/>Your username and password<br/>do not match.').slideDown();
            $('input#username,input#password').val('');
            $('button.login').html('Log in');
            return false;
          } else if (data.response[0].msg === 'Successfully Authenticated') {
            if (data.response[0].supporter === '0') {
              $('.alert').html('During testing of the new jukebox, only supporters will have access.').slideDown();
              $('input#username,input#password').val('');
              $('button.login').html('Log in');
              ReactGA.event({category: 'Jukebox', action: 'User, '+data.response[0].userid+', tried logging into the web app but is not a supporter'});
              return false;
            } else {
              localStorage.setItem('userid', data.response[0].userid);
              localStorage.setItem('auth_token', data.response[0].auth_token);
              ReactGA.event({category: 'Jukebox', action: 'User, '+data.response[0].userid+', logged into the web app'});
              window.location = '/';
            }
          }
      })
      .catch((error) => {
        console.error(error);
        $('.alert').html('<strong>Oh boy!</strong><br/>We encountered an error.<br>'+error).slideDown();
        $('input#username,input#password').val('');
        $('button.login').html('Log in');
        ReactGA.event({category: 'Error', action: 'User cannot log in'});
      });
    } else {
      $('.alert').html('<strong>Gawrsh!</strong><br/>I think you forgot<br/>a username and/or password.').slideDown();
      $('input#username,input#password').val('');
      $('button.login').html('Log in');
      ReactGA.event({category: 'Jukebox', action: 'User used wrong username and/or password'});
    }
  }

  render() {
    if (this.props.isLoggedIn) {
      return (
        <div id="login">
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-4 col-md-3 col-12"></div>
              <div className="col-lg-4 col-md-6 col-12">
                <div className="shadow-sm p-3 mb-5 bg-white rounded box">
                  <h5 className="text-center">You are logged in as <em>{this.props.username}</em>.<br/>Not you? <NavLink to="/logout/">Log out</NavLink>.</h5>
                </div>
              </div>
              <div className="col-lg-4 col-md-3 col-12"></div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div id="login">
        <div id="main">
          <div className="container h-100">
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-lg-4 col-md-3 col-sm-12"></div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="shadow-sm p-3 mb-5 bg-white rounded box">
                  <h5 className="text-center">
                    <img src="https://www.d-cot.com/wp-content/themes/dcot-child/images/emojis/mickey-balloon.png" alt="balloon" width="64" />
                    Welcome Home
                  </h5>
                  <div className="alert alert-danger text-center" role="alert"></div>

                  <div className="form-group">
                    <input autoCapitalize="off" type="text" className="form-control" id="username" name="username" placeholder="Username" onChange={(event) => this.handleUserInput(event)} />
                  </div>
                  <div className="form-group">
                    <input autoCapitalize="off" type="password" className="form-control" id="password" name="password" placeholder="Password" onChange={(event) => this.handleUserInput(event)} />
                  </div>
                  <button className="btn btn-primary btn-block login" onClick={(event) => this.validate()}>Log In</button>
                  <a href="https://www.d-cot.com/wp-login.php?action=lostpassword&hide_my_wp=110407" rel="noopener noreferrer" target="_blank" className="btn btn-link btn-block btn-sm" data-toggle="tooltip" data-placement="bottom" title="Don't worry, we'll help you log in.">Forgot password?</a>
                </div>
              </div>
              <div className="col-lg-4 col-md-3 col-sm-12"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
