import React, { Component } from 'react';
import '../MusicCatalog/MusicCatalog.css';
import $ from 'jquery';
import 'datatables.net-dt';
import 'datatables.net-bs4';
import ReactGA from 'react-ga';
ReactGA.pageview(window.location.pathname + window.location.search);

class MusicCatalog extends Component {
  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip();
    $('#catalog-tracks').DataTable({});
  }

  render() {
    return (
      <div id="music-catalog">
        <div id="main">
          <div className="container-fluid music-catalog">
            <div className="row">
              <div className="col-12">
                <h2 className="mt-3">Music Catalog</h2>
                <table id="catalog-tracks" className="table table-hover table-striped dt-responsive track-list" cellSpacing="0" width="100%">
                  <thead>
                   <tr>
                     <th scope="col" width="12%">Playlist</th>
                     <th scope="col" width="8%">Date Added</th>
                     <th scope="col">Track Name</th>
                   </tr>
                  </thead>
                  <tbody>{this.props.catalog}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MusicCatalog;
