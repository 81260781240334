import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import logo from './../../images/logo.png';
import './Menu.css';
import $ from 'jquery';

class Menu extends Component {
  constructor(props) {
    super(props);
    this.darkModeSelect = this.darkModeSelect.bind(this);
  }

  darkModeSelect() {
    if ($('input#dark-mode:checked').length > 0) {
      //if not selected, we enable dark mode
      $('body').addClass('dark');
      localStorage.setItem('darkmode', 'yes');
      console.log('shades are on');
    } else {
      $('body').removeClass('dark');
      localStorage.setItem('darkmode', 'no');
      console.log('let the sunshine in');
    }
  }

  componentDidMount() {
    let darkMode = localStorage.getItem('darkmode');
    if (darkMode === 'yes') {
      $('input#dark-mode').prop( 'checked', true );
      $('body').addClass('dark');
      console.log('shades are on');
    } else {
      $('input#dark-mode').prop( 'checked', false );
      $('body').removeClass('dark');
      console.log('let the sunshine in');
    }
  }

  render() {
    return (
      <nav id="menu" className="navbar navbar-expand-lg fixed-top">
        <a href="https://www.d-cot.com/" target="_blank"><img src={logo} className="logo navbar-brand" alt="D-COT Logo"/></a>
        <ul className="navbar-nav mr-auto"></ul>
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dark-switch">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="dark-mode" onClick={this.darkModeSelect} />
            <label class="custom-control-label" for="dark-mode">Dark Side</label>
          </div>
          </li>
          <li className="nav-item dropdown">
            <a className="nav-link dropdown-toggle" id="navbar-dropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              {this.props.avatar}
            </a>
            <div className="dropdown-menu dropdown-menu-right text-right" aria-labelledby="navbar-dropdown">
              <NavLink exact className="dropdown-item" to="/"><i className="fa fa-headphones" aria-hidden="true"></i> Jukebox</NavLink>
              <NavLink className="dropdown-item" to="/catalog/"><i className="fa fa-bars" aria-hidden="true"></i> Music Catalog</NavLink>
              <a className="dropdown-item" href="https://www.d-cot.com/dashboard/" target="_blank" rel="noopener noreferrer"><i className="fa fa-dashboard" aria-hidden="true"></i> My Dashboard</a>
              <NavLink className="dropdown-item" to="/logout/"><i className="fa fa-sign-out" aria-hidden="true"></i> Logout</NavLink>
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}

export default Menu;
