import React, {Component} from 'react';
import { Smile } from 'react-feather';
import { Picker } from 'emoji-mart';
//import ReactTextareaAutocomplete from '@webscopeio/react-textarea-autocomplete';
import './Input.css';
import 'emoji-mart/css/emoji-mart.css';

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
        message: "",
        showEmojiPicker: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.addEmoji = this.addEmoji.bind(this);
    this.toggleEmojiPicker = this.toggleEmojiPicker.bind(this);
  }
  handleChange(e) {
    this.setState({
        message: e.target.value
    })
  }
  handleSubmit(e) {
    e.preventDefault();
    this.props.onSubmit(this.state.message);
    this.setState({
        message: ''
    })
  }
  handleKeyPress(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleSubmit();
    }
  }
  toggleEmojiPicker() {
    this.setState({
      showEmojiPicker: !this.state.showEmojiPicker
    });
  }
  addEmoji(emoji) {
    console.log(emoji);
    const { message } = this.state;
    const text = `${message}${emoji.colons}`;

    this.setState({
      message: text,
      showEmojiPicker: false,
    });
  }

  render() {
    const dcotEmojis = [
      {
        name: 'Mickey Smile',
        short_names: ['mickey-smile'],
        colons: ':mickey-smile:',
        text: '',
        emoticons: [':)'],
        keywords: ['mickey-smile'],
        imageUrl: 'https://www.d-cot.com/wp-content/themes/dcot-child/images/emojis/mickey-smile.png',
        customCategory: 'D-COT'
      }
    ]

    const notFound = () => <img alt="not found" src='https://github.githubassets.com/images/icons/emoji/octocat.png' />
    const {
      showEmojiPicker,
    } = this.state;
    return (
      <form onSubmit={this.handleSubmit} className="input-field form-inline">
        <button
          type="button"
          className="toggle-emoji btn"
          onClick={this.toggleEmojiPicker}
        >
          <Smile />
        </button>
        {showEmojiPicker ? (
          <Picker custom={dcotEmojis} notFound={notFound} set="facebook" onSelect={this.addEmoji} title='Pick your emoji…' emoji='point_up' style={{ position: 'absolute', bottom: '40px', right: '15px' }} />
        ) : null}
        <input autoComplete="off" id="chat-message-input" className="message-input form-control mr-sm-2" type="text" name="message" onChange={this.handleChange} value={this.state.message} onKeyUp={this.props.sendTypingEvent}/>
        <input className="message-submit btn btn-primary" type="submit" value="send" />
      </form>
    )
  }
}

export default Input;
