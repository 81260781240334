import React, { Component } from 'react';
import './Tracks.css';

class Tracks extends Component {
  render() {
    return (
      <div id="tracks" className="track-list">
        <ul id="track-group" className="list-group">
          {this.props.tracks}
        </ul>
      </div>
    );
  }
}

export default Tracks;
